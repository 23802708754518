import React, { useState } from 'react';
import { HStack, Button, Icon, VStack, Input, Box, FormControl, Text, View } from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';
import FormWrapper, { trackSubmission, reportSubmissionError, reportTouched } from './FormWrapper'
import { getLanguageFromUrl, validateEmail } from "./utils";
import { subscribe } from "./firebase";
import translations from './translations.json'
import { useLanguage } from './LanguageContext';

import Svg, { Path } from 'react-native-svg';

export const GoogleLogoSvg = (props) => (
  <Svg width="24" height="24" viewBox="0 0 48 48" {...props}>
    <Path fill="#4285F4" d="M46.5 24.5c0-1.5-.1-3-.3-4.5H24v9h12.7c-.6 3-2.4 5.4-4.8 7v5.6h7.8c4.5-4.1 7-10.2 7-17.1z" />
    <Path fill="#34A853" d="M24 48c6.5 0 11.9-2.1 15.8-5.6L32 37c-2.1 1.4-4.8 2.2-8 2.2-6.1 0-11.2-4.1-13-9.6H3.1v6C7 43.1 14.7 48 24 48z" />
    <Path fill="#FBBC05" d="M10.9 28.7C9.9 25.9 9.9 22 10.9 19l-6.7-5.2c-4.2 8.3-4.2 18.3 0 26.6L10.9 28.7z" />
    <Path fill="#EA4335" d="M24 9.8c3.3 0 6.3 1.1 8.7 3.3l6.5-6.5C34.7 2.6 29.3 0 24 0 14.7 0 7 4.9 3.1 12.2l6.8 5.2c1.8-5.5 6.9-9.6 13-9.6z" />
  </Svg>
);

export const AppleLogoSvg = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <Path d="M19.664 12.512c-.028-3.54 2.895-5.24 3.022-5.316-1.645-2.396-4.207-2.727-5.115-2.757-2.17-.23-4.238 1.285-5.34 1.285-1.1 0-2.792-1.253-4.585-1.22-2.357.035-4.527 1.37-5.745 3.483-2.44 4.21-.622 10.426 1.75 13.838 1.154 1.666 2.524 3.529 4.31 3.461 1.75-.067 2.407-1.107 4.522-1.107 2.115 0 2.704 1.107 4.55 1.068 1.883-.036 3.068-1.684 4.203-3.359 1.323-1.982 1.87-3.903 1.896-4.008-.042-.018-3.623-1.386-3.65-5.512zm-4.106-10.027c.948-1.138 1.594-2.717 1.414-4.293-1.366.057-3.022.912-4.001 2.05-.876 1.02-1.642 2.662-1.434 4.234 1.524.118 3.075-.773 4.021-1.991z" />
  </Svg>
);

const SignUpButton = (props) => {
  const { icon, text, onPress, colors } = props
  return <Button
    w="100%" variant="solid" backgroundColor={colors?.submitBtn || "gray.100"} _text={{
      color: 'gray',
    }}
    borderColor="gray.800"
    size="sm"
    borderStyle="solid"
    borderWidth={2}

    _hover={{
      backgroundColor: "blueGray.500",
      _text: {
        color: 'white',
      }
    }}
    mt={4}
    leftIcon={icon}
    onPress={onPress}
  >
    {text}
  </Button>
}

const SignUpComponent = (props) => {
  const { colors, id } = props

  const { language } = useLanguage()
  const lang = language || getLanguageFromUrl()
  const { signup, withGoogle, withApple, thanks, priseTitle, prise, priseDescr } = translations['SignUp'][lang] || props
  const [selectedProvider, setSelectedProvider] = useState(null);

  const handleButtonClick = (provider) => {
    setSelectedProvider(provider);
  };

  const [socialId, setSocialId] = useState('');
  const [touched, setTouched] = useState(false)
  const [inputErr, setInputErr] = useState('')
  const [isSignedUp, setIsSignedUp] = useState(false);
  const maxChar = 50


  const onInputChange = (txt) => {

    if (!touched) {
      setTouched(true)
      reportTouched(id)
    }

    let isValid = txt?.length < maxChar;
    let error = 'Too long! Max char length ' + maxChar
    isValid = validateEmail(txt);
    error = 'Not a valid email address'

    if (!isValid) {
      setInputErr(error)
    } else {
      setInputErr('')
    }
    setSocialId(txt)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if all values are provided
    if (!socialId.trim()) {
      alert('Please fill out.');
      return;
    }

    if (inputErr) {
      alert('Please privide a correct email address' + inputErr)
    }

    try {
      let adaptedSocialId = socialId.substring(0, maxChar) // just the first maxChar chars
      await subscribe(adaptedSocialId, "email")
      trackSubmission(id)
      setIsSignedUp(true)
      alert(`Succesfuly Signed up ${socialId} to Stylu!`)
      setSocialId('')
    } catch (error) {
      console.error("Error writing document: ", error);
      reportSubmissionError(id)
      alert('Error! Something went wrong while sending your subscription. Please reach us at info@stylu.de');
    }
  };

  return (<View h="100%" w="100%">
    {isSignedUp ? (
      <VStack alignItems="center" justifyContent="center" mb={0} mt={1} px={2} h="100%" >
        <HStack space={0}>
          <Text fontSize="lg" fontWeight="bold">{thanks}</Text>
          <Text fontSize="lg" fontWeight="bold" color="green.500" ml={1}>Stylu!</Text>
        </HStack>
        <Text textAlign="center">
          {priseTitle}
          <Text bold color="green.500"> {prise} </Text>
        </Text>
        <Text> {priseDescr}</Text>
      </VStack >
    ) : (
      <Box w="100%" alignItems="center" h="100%">
        {selectedProvider ? (
          <VStack w="70%" h="100%" space={1} alignItems="center" py={2}>
            <FormWrapper id={id} onSubmit={handleSubmit}>
              <FormControl isInvalid={inputErr} w="75%" maxW="300px">

                <Input
                  _focus={{ borderColor: 'tertiary.500' }}
                  borderColor="gray.800"
                  borderStyle="solid"
                  borderWidth={2}
                  value={socialId}
                  onChangeText={onInputChange}
                  size={{ base: "lg", lg: "2xl" }}
                  width="100%" placeholder="email"
                />
                <SignUpButton
                  colors={colors}
                  icon={<Icon as={FontAwesome5} name="envelope" size={"sm"} color="black" />}
                  text={signup}
                  onPress={handleSubmit}
                />

              </FormControl>
            </FormWrapper>

          </VStack>
        ) : (
          <VStack w="70%" h="100%" space={1} alignItems="center">
            <SignUpButton
              icon={<Icon as={GoogleLogoSvg} />}
              text={withGoogle}
              onPress={() => handleButtonClick('Google')}
            />
            <SignUpButton
              icon={<Icon as={AppleLogoSvg} />}
              text={withApple}
              onPress={() => handleButtonClick('Apple')}
            />
          </VStack>
        )}
      </Box>
    )
    }</View>)
};

export default SignUpComponent;

SignUpComponent.defaultProps = {
  colors: {
    submitBtn: '#FFE45E'
  },
  "signup": "Sign up",
  "withGoogle": "Sign up with Google",
  "withApple": "Sign up with Apple",
  "thanks": "Thanks for joining",
  "priseTitle": "You will receive",
  "prise": "€35",
  "priseDescr": "worth of points to use on our platform. We will notify you about our exclusive launch 🚀 in 2024."
}

