import React, { useState, useEffect } from "react";
import {
  useBreakpointValue,
  VStack, HStack, Box,
  View, Modal
} from "native-base";

import Subscription from './Subscription'
import HeaderComponent from "./HeaderComponent";
import About from './About'
import AppUxScreens from './AppUxScreens'
import AppFlowShowCase from './AppFlowShowCase'
import SellingPoints from './SellingPoints.jsx'
import FooterComponent from './FooterComponent'
import EcoComponent from './Eco'
import CookieBanner from './CookieBanner'
import { getQueryParam, isTruthy } from "./utils";
import { useLanguage } from './LanguageContext'

// import Hero from "./Hero.jsx";
import MinimalHero from './MinimalHero'
import colors from './colors.json'
import LazyVideoLoader from './videoplayer/LazyLoader.jsx'

export const defaultPallet = {
  main: "#22d3ee", // bluish
  secondary: "#FFE45E", //"yellow",
  complementary: "#FF6392", // pinkish
  specialText: "#c1466b", // dark pink
  white: "#fff", // white
  green: "#10b981",
  altText: "muted.800",
  text: "muted.800",
}

const getColors = () => {
  const variation = getQueryParam('c');
  const keys = Object.keys(colors)

  const colorP = colors && colors[variation]
  return Object.assign({}, defaultPallet, colorP)
}

const shouldShowVideoPlayer = () => {
  const noVP = getQueryParam('nvp')
  return isTruthy(noVP) ? false : true
}


const SubsModal = (props) => {
  const { colors, modalVisible, onClose, callBack } = props
  const { language } = useLanguage();

  return <Modal isOpen={modalVisible} onClose={onClose} size="xl"
  >
    <Modal.Content marginBottom="auto" marginTop={20} padding={0}>
      <Modal.CloseButton
        right='2'
        top='1'
        _icon={{
          color: 'muted.800',
          size: '5'
        }} />
      <Modal.Body padding={0} >
        <Subscription
          id="modal"
          lang={language}
          callBack={callBack}
          colors={{
            bg: colors.complementary,
            bgFade: colors.main,
            input: colors.altText,
            heading: colors.text,
            reward: colors.secondary,
            button: colors.secondary,
            buttonText: colors.altText,
            text: colors.text
          }} />
      </Modal.Body>
    </Modal.Content>
  </Modal>
}

export default function LandingComponent(props) {

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true
  });

  const showVidePlayer = isDesktop ? false : shouldShowVideoPlayer()

  const space = useBreakpointValue({
    sm: 10,
    lg: 16
  })

  const [showSubs, setShowSubs] = useState(false)

  const colorPallet = getColors()
  const subsId = "subscription"
  const learnId = "learnmore"

  const LOCAL_STORAGE_KEY_SUBS = "SUBSCRIBED"
  // display sibcsripton after 4 seconds
  const subscribed = localStorage.getItem(LOCAL_STORAGE_KEY_SUBS);
  useEffect(() => {
    if (!showSubs) {
      const timer = setTimeout(() => {
        setShowSubs(true);  // This will set showBanner to true after 2 seconds
      }, 4000);

      return () => clearTimeout(timer);
    }

  }, [subscribed])

  return <VStack h="100%" w="100%" >
    <HeaderComponent subsId={subsId} learnId={learnId} colors={colorPallet} contactCallBack={() => setShowForm(true)} />

    <SubsModal
      colors={colorPallet}
      modalVisible={showSubs}
      onClose={() => setShowSubs(false)}
      callBack={(submitted) => {
        setShowSubs(false)
        if (submitted) {
          localStorage.setItem(LOCAL_STORAGE_KEY_SUBS, true);
        }
      }}
    />


    <View minH={350} >
      <MinimalHero subsId={subsId} learnId={learnId} colors={colorPallet} />
    </View>

    <View mt={{ base: 5, lg: 10 }} >
      <AppFlowShowCase tagId={learnId} colors={{ heading: colorPallet.text, bgText: colorPallet.main }} />
    </View>

    <Box width="100%" alignSelf="center" mt={space}
      bg="white"

      px={{ base: 0, lg: 20 }}
    >
      <Subscription
        id="top"
        colors={{
          bg: colorPallet.complementary,
          bgFade: colorPallet.main,
          input: colorPallet.altText,
          heading: colorPallet.text,
          reward: colorPallet.secondary,
          button: colorPallet.secondary,
          buttonText: colorPallet.altText,
          text: colorPallet.text
        }} />

    </Box>

    <View w={{ base: '100%', lg: '70%' }} alignSelf="center" mt={16}>
      <AppUxScreens colors={{ heading: colorPallet.text, bgText: colorPallet.green }} />
    </View>

    <SellingPoints colors={{
      bg: colorPallet.main,
      heading: colorPallet.text,
      bgHeading: 'transparent',
      text: colorPallet.text,
      iconBg: colorPallet.secondary,
      icon: colorPallet.text,
    }} />

    {showVidePlayer && <View  >
         <LazyVideoLoader />
      </View>
      }

    <HStack backgroundColor={colorPallet.white} py={10}>
      <View width={{ base: "100%", lg: "80%" }} >
        { /*<Mission colors={{ heading: colorPallet.main, bgText: colorPallet.secondary }} textWidth={{ base: '100%', lg: '50%' }} />
        */}
        <EcoComponent colors={{ heading: colorPallet.text, bgText: colorPallet.green }} />

      </View>
      <Box width={{ base: 0, lg: "20%" }} height={{ base: 0, lg: "80%" }}
        borderStyle="dotted" borderColor={colorPallet.green} borderLeftWidth="4" alignSelf="center"
      />
    </HStack>
    <HStack mt={space}>
      <Box width={{ base: 0, lg: "20%" }} height={{ base: 0, lg: "80%" }}
        borderStyle="dotted" borderColor={colorPallet.green} borderRightWidth="4" alignSelf="center"
      />
      <View width={{ base: "100%", lg: "80%" }}
        alignItems='end' >
        <About colors={{ heading: colorPallet.text, bgText: colorPallet.green }} textWidth={{ base: '100%', lg: '50%' }} />
      </View>
    </HStack>

    <Box width="100%" alignSelf="center"
      bg="gray.900"
      py={20} px={{ base: 0, lg: 20 }}
    >
      <Subscription
        id="bottom"
        colors={{
          bg: colorPallet.green,
          bgFade: colorPallet.main,
          input: colorPallet.altText,
          heading: colorPallet.text,
          reward: colorPallet.secondary,
          button: colorPallet.secondary,
          buttonText: colorPallet.altText,
          text: colorPallet.text
        }} />


    </Box>
    <FooterComponent bg="blueGray.600" colorScheme={'blueGrey'} />
    <CookieBanner colors={colorPallet} />

  </VStack>
}

LandingComponent.defaultProps = {
  title: 'Turn your wardrobe into an environmentally friendly business',
  subtitle: 'No commission, 0 emissions. sustainable fashion simplified!',
}