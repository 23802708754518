
import React from "react";
import {
    useBreakpointValue,
    VStack, Text, Heading, Flex, Box,
} from "native-base";
import { FontAwesome5 } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useLanguage } from './LanguageContext'

import { getLanguageFromUrl } from "./utils";
import translations from './translations.json';


const Item = (props) => {
    const { icon, text, colors } = props || {}
    const { title, description } = text || {}

    const iconSize = useBreakpointValue({ base: 32, lg: 64 })

    return <VStack space={4} alignItems="center" flex={1} w="100%" h={{ base: 300, lg: 395 }}>
        <Box
            bg={colors.iconBg}
            borderRadius="full"
            width={iconSize * 4}
            height={iconSize * 4}
            alignItems="center"
            justifyContent="center"
        >
            <FontAwesome5 name={icon} size={iconSize} w="100%" color={colors.icon} />

        </Box>
        <VStack w={{ base: "85%", lg: "70%" }}>

            <Text color={colors.text} fontWeight="bold" fontSize={15} textAlign="center" mb={2}>{title}</Text>
            <Text color={colors.text} fontSize={14} textAlign="center">{description}</Text>
        </VStack>
    </VStack>
}

export default function SellingPoints(props) {
    const flexDir = useBreakpointValue({
        base: "column",
        lg: "row"
    });
    
    const { language } = useLanguage();
    const lang = language || getLanguageFromUrl()
    const { title, items } = translations['SellingPoints']?.[lang] || props
    const { colors } = props
    const icons = [
        "donate",
        "lightbulb",
        "seedling"
    ]

    return <LinearGradient
        colors={[colors.bg, '#b3b08c']} // Adjust the colors as needed
        start={{ x: 0, y: 0 }} // Starting point of gradient
        end={{ x: 1, y: 1 }} // Ending point of gradient
        style={{ flex: 1 }}

    >
        <VStack alignItems="center" py={3} minH={{ base: 1100, lg: 400 }}>

            <Heading color={colors.heading} fontSize={{ base: 20, lg: 30 }}           
                p={5}
                mb={2}
                textAlign='center'
                w={{ base: "100%", lg: "50%" }}
                fontWeight="500">{title}
            </Heading>


            <Flex direction={flexDir} justifyContent="space-between" w="100%" flex={2} >
                {
                    items.map((item, index) => (<Item key={index} text={item} icon={icons[index]} colors={colors} />))
                }
            </Flex>

        </VStack >
    </LinearGradient>
}

SellingPoints.defaultProps = {
    colors: {
        bg: 'white',
        heading: 'tertiary.500',
        bgHeading: 'transparent',
        text: 'black',
        iconBg: 'white',
        icon: 'black'
    },
    title: "Why Stylu?",
    items: [
        {
            title: 'No Commision',
            description: 'We get 0 commission from buyers and sellers. List your second hand clothes and buy new items without paying an extra cent to the middle man.'
        },
        {
            title: 'Awareness Environmental Impact',
            description: 'We give you awareness on your environmental impact. With every item that you sell or buy you can see how much you contirbuted to keep the planet clean. We calculate for you how much CO2 did you save and how much literes of water kept clean'
        },
        {
            title: 'Support Green Businesses',
            description: 'We support and promote small green businesses in your local area. We partner with these businesses to promote a greener lifestyle for all of us'
        }
    ]
}